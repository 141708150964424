exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-giveaway-js": () => import("./../../../src/pages/giveaway.js" /* webpackChunkName: "component---src-pages-giveaway-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-idea-js": () => import("./../../../src/templates/idea.js" /* webpackChunkName: "component---src-templates-idea-js" */),
  "component---src-templates-ideas-list-js": () => import("./../../../src/templates/ideas-list.js" /* webpackChunkName: "component---src-templates-ideas-list-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-recipes-list-js": () => import("./../../../src/templates/recipes-list.js" /* webpackChunkName: "component---src-templates-recipes-list-js" */),
  "component---src-templates-review-js": () => import("./../../../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */),
  "component---src-templates-reviews-list-js": () => import("./../../../src/templates/reviews-list.js" /* webpackChunkName: "component---src-templates-reviews-list-js" */)
}

